import SubscriptionStatisticsType from "../../../models/SubscriptionStatisticsType";
import SubscriptionUsage from "../../../models/SubscriptionUsage";
import React from "react";
import {AxiosError} from "axios";
import CreateProviderRequest from "../../../models/requests/CreateProviderRequest";
import ProviderContact from "../../../models/ProviderContact";

export default class Generals {

    static getSystemStatusDescription(status: SubscriptionStatisticsType): string {
        let subscriptionDescriptions: Record<SubscriptionStatisticsType, string> = {
            [SubscriptionStatisticsType.creditsUsage]: "Credite ramase",
            [SubscriptionStatisticsType.memoryUsage]: "Memorie folosita",
            [SubscriptionStatisticsType.usersUsage]: "Numar de utilizatori"
        };

        return subscriptionDescriptions[status];
    }

    static getSystemStatusCorrectDisplay(status: SubscriptionUsage) {
        switch (status.title) {
            case SubscriptionStatisticsType.creditsUsage:
                return <p>{status.usage} {status.total == null ? "" : ("/ " + status.total)}</p>
            case SubscriptionStatisticsType.memoryUsage:
                return <div
                    className="w-full bg-gray-200 rounded-full h-fit p-2">
                    <div
                        className="bg-accent-main-500 h-4 rounded-full transition-all duration-300"
                        style={{width: `${parseFloat(status.usage) * 100}%`}}
                    />
                </div>;
            case SubscriptionStatisticsType.usersUsage:
                return <div
                    className="w-full bg-gray-200 rounded-full h-fit p-2">
                    <div
                        className="bg-accent-main-500 h-4 rounded-full transition-all duration-300"
                        style={{width: `${parseFloat(status.usage) / parseFloat(status.total ?? 0) * 100}%`}}
                    />
                </div>;
            default:
                return <></>;
        }
    }

    static buildTextForEducationCode(code: number) {
        switch (code) {
            case 1:
                return "";

            default:
                return "";
        }
    }

    static buildTextForOcrLabelValue = (label: string) => {
        switch (label) {
//CUI
            case "nr_de_ordine_registrul_comertului":
                return "Numar Registrul Comertului";

            case "cod_caen":
                return "Cod Caen";

            case "nr":
                return "Numar";

            case "activitate_principala":
                return "Activitate principala";

            case "cod_unic_de_inregistrare":
                return "Cod unic de inregistrare";

            case "euid":
                return "J Reg";

            case "data_infintare":
                return "Data infiintare";

            case "oficiul_registrului":
                return "Oficiul inregistrarii";

            case "seria":
                return "Serie";

            case "sediu_social":
                return "Sediu social";

            case "data_eliberarii":
                return "Data eliberarii";


//ACMR-AN-AE
            case "adresa_asigurat":
                return "Adresa Asigurat";

            case "asigurator":
                return "Asigurator";

            case "autotractor":
                return "Autotractor";

            case "cod_asf":
                return "Cod ASF";

            case "cod_intern_emitent":
                return "Cod Intern Emitent";

            case "cui_asigurat":
                return "CUI Asigurat";

            case "data_finalizare_valabilitate":
                return "Data Finalizare Valabilitate";

            case "data_incepere_valabilitate":
                return "Data Incepere Valabilitate";

            case "data_intocmire":
                return "Data Intocmire";

            case "detalii_rate":
                return "Detalii Rate";

            case "info_vehicul":
                return "Info Vehicul";

            case "inlocuieste_reinnoieste":
                return "Reinnoieste";

            case "marca_vehicul":
                return "Marca Vehicul";

            case "mentiuni_speciale":
                return "Mentiuni Speciale";

            case "inlocuieste_reinnoieste":
                return "Reinnoieste";

            case "nr_auto":
                return "Nr AUTO";

            case "numar_polita":
                return "Nr Polita";

            case "nume_asigurat":
                return "Nume Asigurat";

            case "nume_broker":
                return "Nume Broker";

            case "perioada_asigurare":
                return "Perioada Asigurare";

            case "polita_pe_perioada_determinata":
                return "Polita Periaoda Determinata";

            case "rate":
                return "Rate";

            case "remorca":
                return "Remorca";

            case "serie_polita":
                return "Serie Polita";

            case "serie_sasiu":
                return "Serie Sasiu";

            case "suma_asigurata":
                return "Suma Asigurata";

            case "tabel_nr_auto":
                return "Tabel Nr AUTO";

            case "tip_plata_asigurare":
                return "Tip Plata Asigurare";

            case "tip_vehicul":
                return "Tip Vehicul";

            case "numar-polita":
                return "Numar polita";

            case "titlu_document":
                return "Titlu Document";

            case "valoare_asigurare":
                return "Valoare Asigurare";


//CI
            case "cetatenie":
                return "Cetatenie";

            case "cnp":
                return "CNP";

            case "domiciliu":
                return "Domiciliu";

            case "emitent":
                return "Emitent";

            case "loc_nastere":
                return "Loc Nastere";

            case "nr":
                return "Numar";

            case "nume":
                return "Nume";

            case "prenume":
                return "Prenume";

            case "serie":
                return "Serie";

            case "valabilitate":
                return "Valabilitate";

//DP
            case "adresa_platitor":
                return "Adresa Platitor";

            case "banca_beneficiar":
                return "Banca Beneficiar";

            case "banca_platitor":
                return "Banca Platitor";

            case "cont_beneficiar":
                return "Cont Beneficiar";

            case "cont_platitor":
                return "Cont Platitor";

            case "CUI_CNP_beneficiar":
                return "Identificator Beneficiar";

            case "CUI_CNP_platitor":
                return "Identificator Platitor";

            case "data_crearii":
                return "Data Crearii";

            case "data_tranzactiei":
                return "Data Tranzactiei";

            case "detalii":
                return "Detalii";

            case "moneda":
                return "Moneda";

            case "numar-ordin":
                return "Numar Ordine";

            case "nume_beneficiar":
                return "Nume Beneficiar";

            case "nume_platitor":
                return "Nume Platitor";

            case "referinta":
                return "Referinta";

            case "referinta_interna":
                return "Referinta Interna";

            case "semnatari":
                return "Semnatari";

            case "status":
                return "Status";

            case "suma":
                return "Suma";

            case "swift_beneficiar":
                return "Swift Beneficiar";

            case "swift_platitor":
                return "Swift Platitor";

            case "tip_transfer":
                return "Tip Transfer";

            case "titlu":
                return "Titlu";


// LT-CC-LE-OCR
            case "adresa_firma":
                return "Adresa Firma";

            case "cui_firma":
                return "CUI Firma";

            case "data_emitere":
                return "Data Emitere";

            case "data_finalizare_valabilitate":
                return "Data Finalizare Valabilitate";

            case "data_incepere_valabilitate":
                return "Data Incepere Valabilitate";

            case "emisa_in":
                return "Emisa In";

            case "emisa_la":
                return "Emisa La";

            case "loc_emitere":
                return "Loc Emitere";

            case "nr_auto":
                return "Nr AUTO";

            case "nr_licenta":
                return "Nr Licenta";

            case "nume_firma":
                return "Nume Firma";

            case "observatii":
                return "Observatii";

            case "serie_licenta_expeditie":
                return "Serie Licenta Expeditie";

            case "valabila_de_la":
                return "Valabila De La";

            case "detalii":
                return "Detalii";

//TL
            case "adresa_detinator":
                return "Adresa Detinator";

            //case "adresa_utilizator":
            // return "Adresa Utilizator";

            case "categorie_vehicul":
                return "Categorie Vehicul";

            case "data_inmatriculare":
                return "Data Inmatriculare";

            case "data_prima_inmatriculare":
                return "Data Prima Inmatriculare";

            case "denumire_comerciala":
                return "Denumire Comerciala";

            case "detinator_vehicul":
                return "Detinator Vehicul";

            case "marca":
                return "Marca";

            case "masa_maxima":
                return "Masa Maxima";

            case "nr_auto":
                return "Nr AUTO";

            case "nr_omologare":
                return "Nr Omologare";

            case "nr_talon":
                return "Nr Talon";

            case "observatii":
                return "Observatii";

            //case "prenume_detinator":
            // return "Prenume Detinator";

            case "serie_sasiu":
                return "Serie Sasiu";

            case "tip_masina":
                return "Tip Masina";
            //
            // case "utilizator_vehicul":
            //     return "Utilizator Vehicul";


            case "detalii":
                return "Detalii";
            default:
                return "Camp nerecunoscut";
        }
    }


    static buildError = (error: AxiosError) => {
        switch (error.response?.status ?? 0) {
            case 401:
                return "Nu esti autentificat";

            case 403:
                return "Nu ai access la resursa";

            case 406:
                return "Nu ai credite sau spatiu de stocare ramas";

            case 400:
            case 404:
                return "Am intampinat o eroare. Incearca mai tarziu!";

            default:
                return "Am intampinat o eroare";
        }
    }


    static checkIfProviderCreateHasEmptyFields = (createProviderRequest: CreateProviderRequest) => {
        return createProviderRequest.cui === "" ||
            createProviderRequest.name === "" ||
            createProviderRequest.gei === "" ||
            createProviderRequest.city === "" ||
            createProviderRequest.country === "" ||
            createProviderRequest.address === "" ||
            createProviderRequest.county === "";
    }

    static checkIfProviderContactCreateHasEmptyFields = (providerContactDetails: ProviderContact) => {
        return providerContactDetails.email === "" ||
            providerContactDetails.firstName === "" ||
            providerContactDetails.lastName === "" ||
            providerContactDetails.jobPosition === "" ||
            providerContactDetails.mobilePhone === "";
    }

    static debounce = <T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void => {
        let timer: ReturnType<typeof setTimeout>;

        return (...args: Parameters<T>) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

}