import {Link, useLocation, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import AiDocumentCheckerService from "../../../services/AiDocumentCheckerService";
import {apiRoutesAiDocumentChecker} from "../../../commons/environment/EnvironmentRoutes";
import {IoChevronBack} from "react-icons/io5";
import AiDocumentCheckerRouter from "../../../routers/AiDocumentCheckerRouter";
import Utils from "../../../commons/functionals/utils/Utils";
import Loading from "../../../commons/component/loading/views/Loading";
import {toast, Toaster} from "react-hot-toast";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import ProvidersService from "../../../services/ProvidersService";
import AiDocumentCheckerProgressBar from "../containers/AiDocumentCheckerProgressBar";
import {AnalyseTask, AnalysisTaskStages} from "../../../models/AnalyseTask";
import {DocumentTypeEnhanced} from "../../../models/DocumentTypeEnhanced";
import Generals from "../../../commons/component/generals/Generals";
import AiDocumentCheckerTabs from "../containers/AiDocumentCheckerTabs";
import AiDocumentCheckerEducationInsurance from "../containers/AiDocumentCheckerEducationInsurance";
import AiDocumentCheckerProviderComponent from "../containers/AiDocumentCheckerProviderComponent";
import Provider from "../../../models/Provider";

export default function AiDocumentCheckerViewTask() {

    const {user} = useContext(AuthContext);
    const {taskSlug} = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [providers, setProviders] = useState<Provider[]>([]);
    const [analysisTask, setAnalysisTask] = useState<AnalyseTask | null>(null);
    const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

    const [viewMatrix, setViewMatrix] = useState<boolean>(true);

    const [originalPdf, setOriginalPdf] = useState<string | null>(null);
    const [highlightedPdf, setHighlightedPdf] = useState<string | null>(null);
    const [currentGroupFocus, setCurrentGroupFocus] = useState<number>(0);

    const providerService = new ProvidersService();
    const service = new AiDocumentCheckerService();
    const location = useLocation();

    function formatDate(date: string | undefined | null): string {
        if (date === null || date === undefined)
            return "-";

        return new Date(date).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }).replace(',', '');
    }

    useEffect(() => {
        getTask();
        getProviders();
    }, [taskSlug]);

    // UPDATE TASK TIME LOOP
    useEffect(() => {
        if (!isFirstTime) {
            const intervalId = setInterval(() => {
                if (analysisTask?.analysisTaskStages !== AnalysisTaskStages.ERROR && analysisTask?.analysisTaskStages !== AnalysisTaskStages.FINISHED) {
                    getTask();
                } else {
                    clearInterval(intervalId);
                }
            }, 5000);

            return () => clearInterval(intervalId);
        }
    }, [isFirstTime, analysisTask]);

    const getTask = () => {
        if (taskSlug === undefined)
            return;

        if (isFirstTime) {
            setIsLoading(true);
        }
        service.getTask(user!.firmSlug, taskSlug).then((response) => {
            if (isFirstTime) setIsFirstTime(false);
            setAnalysisTask(response);

            if (response.analysisDetails.originalDocumentName != null && response.analysisDetails.photoshopDetectedName != null) {
                service.getPdf(user!.firmSlug, taskSlug ?? "", response.analysisDetails.originalDocumentName).then((response) => setOriginalPdf(URL.createObjectURL(response)));
                service.getPdf(user!.firmSlug, taskSlug ?? "", response.analysisDetails.photoshopDetectedName).then((response) => setHighlightedPdf(URL.createObjectURL(response)));
            }
        })
            .catch((error) => toast.error(Generals.buildError(error)))
            .finally(() => {
                if (isFirstTime) {
                    setIsLoading(false);
                }
            });
    }

    const getProviders = () => {
        providerService.getAllProviders()
            .then(rsp => {
                setProviders(rsp);
            })
            .catch((error) => toast.error(Generals.buildError(error)))
    }

    const buildMetadata = (value: boolean | null): string => {
        return value ?? false ?
            "text-error font-main-bold" :
            "text-green-500";
    }

    const buildToggleMatrixButton = () =>
        <div className="absolute top-0 left-0 w-full z-10 p-5">
            <div onClick={() => setViewMatrix(!viewMatrix)}
                 className={"cursor-pointer p-3 w-fit text-white rounded-lg bg-accent-main-500"}>
                {
                    !viewMatrix ? "Vezi document original" : "Vezi radiografie"
                }
            </div>
        </div>


    return (
        <>
            <div><Toaster/></div>
            {
                isLoading ?
                    <Loading color={"fill-accent-main-500"}/> :
                    <div className={"h-fit"}>
                        <div className="flex items-center justify-between pb-4 h-[50px]">
                            <div className={"flex items-center gap-2"}>
                                <Link
                                    className={"p-3 text-white rounded-lg bg-accent-main-500"}
                                    to={location.pathname.replace(AiDocumentCheckerRouter.viewTaskEntrance + analysisTask!.slug, "")}>
                                    <IoChevronBack/>
                                </Link>
                                <h1 className="text-text-main-color-500 text-xl font-main-bold">
                                    Document cu ID: {analysisTask?.slug}
                                </h1>
                            </div>
                            <div>
                                <Link
                                    to={location.pathname.replace(AiDocumentCheckerRouter.viewTaskEntrance + analysisTask!.slug, "") + AiDocumentCheckerRouter.education}
                                    className={"p-3 text-white rounded-lg bg-accent-main-500"}>
                                    Vezi detalii educatie
                                </Link>
                            </div>
                        </div>
                        <AiDocumentCheckerProgressBar
                            currentStage={analysisTask?.analysisTaskStages ?? AnalysisTaskStages.STARTED}/>
                        <AiDocumentCheckerTabs
                            currentStage={analysisTask?.analysisTaskStages ?? AnalysisTaskStages.STARTED}
                            onTabChanged={setCurrentGroupFocus}/>
                        <div className="flex flex-row gap-3 pb-4">
                            <div className="w-4/6 top-5 h-[calc(100vh-32px)] sticky">
                                {buildToggleMatrixButton()}
                                {
                                    originalPdf === null
                                        ? <div className={"w-full h-full flex justify-center items-center"}>
                                            <Loading color={"fill-accent-main-500"}/>
                                        </div>
                                        : <iframe
                                            title={analysisTask!.slug}
                                            src={viewMatrix ? highlightedPdf! : originalPdf!}
                                            className="w-full h-full rounded-lg"
                                        />
                                }
                            </div>
                            <div className="w-2/6 h-fit flex flex-col gap-3">
                                {
                                    currentGroupFocus === 0 || currentGroupFocus === 1
                                        ? <div className={"bg-white p-4 rounded-lg shadow-xl"}>
                                            <h2 className="text-lg font-main-bold">Analiza document</h2>
                                            <div className={"flex flex-row gap-2"}>
                                                <span>Tip de document:</span>
                                                <span className={"font-main-medium"}>
                                        {Utils.convertToText(analysisTask!.analysisDetails.documentType)}
                                    </span>
                                            </div>
                                            {
                                                analysisTask!.analysisDetails.originalDocumentName == null
                                                    ? <></>
                                                    : <a className={"hover:underline"}
                                                         href={apiRoutesAiDocumentChecker.viewDocument.replaceAll("FIRM_SLUG", user?.firmSlug ?? "").replace('TASK_SLUG', taskSlug ?? "").replace("DOCUMENT_NAME", analysisTask!.analysisDetails.originalDocumentName!)}>
                                                        Descarca document original
                                                    </a>
                                            }
                                            <div className="flex flex-col gap-2 w-full mt-2">
                                                <div className={"flex gap-2 flex-row w-full"}>
                                                    <div className={"w-full"}>
                                                        Autorul documentului
                                                    </div>
                                                    <div className={"w-full"}>
                                                        {analysisTask!.analysisDetails.analysisDocumentMetadata?.author ?? "-"}
                                                    </div>
                                                </div>
                                                <div className={"flex gap-2 flex-row w-full"}>
                                                    <div className={"w-full"}>
                                                        Data creare
                                                    </div>
                                                    <div className={"w-full"}>
                                                        {formatDate(analysisTask!.analysisDetails.analysisDocumentMetadata?.creationDate)}
                                                    </div>
                                                </div>
                                                <div className={"flex gap-2 flex-row w-full"}>
                                                    <div className={"w-full"}>
                                                        Data modificare
                                                    </div>
                                                    <div className={"w-full"}>
                                                        {formatDate(analysisTask!.analysisDetails.analysisDocumentMetadata?.modificationDate)}
                                                    </div>
                                                </div>
                                                <div className={"flex gap-2 flex-row w-full"}>
                                                    <div className={"w-full"}>
                                                        Document Photoshopat
                                                    </div>
                                                    <div
                                                        className={"w-full " + buildMetadata(analysisTask!.analysisDetails.isPhotoshopDetected)}>
                                                        {analysisTask!.analysisDetails.isPhotoshopDetected ?? false ? "DA" : "NU"}
                                                    </div>
                                                </div>
                                                <div className={"flex gap-2 flex-row w-full"}>
                                                    <div className={"w-full"}>
                                                        Referinte blacklist
                                                    </div>
                                                    <div
                                                        className={"w-full " + buildMetadata(analysisTask!.analysisDetails.existsInBlacklist)}>
                                                        {analysisTask!.analysisDetails.existsInBlacklist ? "DA" : "NU"}
                                                    </div>
                                                </div>
                                                <div className={"flex gap-2 flex-row w-full"}>
                                                    <div className={"w-full"}>
                                                        Document editat
                                                    </div>
                                                    <div
                                                        className={"w-full " + buildMetadata((analysisTask!.analysisDetails.analysisDocumentMetadata?.hasFreeText ?? false) || (analysisTask!.analysisDetails.analysisDocumentMetadata?.hasInk ?? false))}>
                                                        {(analysisTask!.analysisDetails.analysisDocumentMetadata?.hasFreeText ?? false) || (analysisTask!.analysisDetails.analysisDocumentMetadata?.hasInk ?? false) ? "DA" : "NU"}
                                                    </div>
                                                </div>
                                                <div className={"flex gap-2 flex-row w-full"}>
                                                    <div className={"w-1/2 flex justify-start items-start italic"}>
                                                        Detectarea caracterelor neregulate
                                                    </div>
                                                    <div className={"flex w-1/2 flex-col justify-start gap-2 items-start"}>
                                                        {
                                                            (analysisTask!.analysisDetails.textInconsistencies ?? []).map((el, i) =>
                                                                <p>{el}</p>)
                                                        }
                                                    </div>
                                                </div>
                                                <div className={"flex"}>
                                                    <p className={"italic text-xs"}>
                                                        *Vă rugăm să analizați cu atenție documentul încărcat și să
                                                        identificați eventuale caractere care prezintă discrepanțe față de
                                                        restul conținutului. În special, vă recomandăm să verificați dacă
                                                        există diferențe de font, dimensiune, culoare
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        : <></>
                                }
                                {
                                    currentGroupFocus === 0 || currentGroupFocus === 2
                                        ? <div className={"bg-white p-4 rounded-lg shadow-xl"}>
                                            <h2 className="text-lg font-main-bold">Informatii extrase</h2>
                                            <AiDocumentCheckerProviderComponent taskSlug={taskSlug ?? ""}
                                                                                providers={providers}
                                                                                taskStage={analysisTask?.analysisTaskStages ?? AnalysisTaskStages.STARTED}
                                                                                taskDetails={analysisTask?.analysisDetails}/>
                                        </div>
                                        : <></>
                                }
                                {
                                    currentGroupFocus === 0 || currentGroupFocus === 3
                                        ? <div className={"bg-white p-4 rounded-lg shadow-xl"}>
                                            <h2 className="text-lg font-main-bold">Educatie</h2>
                                            {
                                                analysisTask?.analysisDetails.documentType === DocumentTypeEnhanced.CMR_INSURANCE ||
                                                analysisTask?.analysisDetails.documentType === DocumentTypeEnhanced.ANEXES ||
                                                analysisTask?.analysisDetails.documentType === DocumentTypeEnhanced.EXPEDITION_INSURANCE
                                                    ? <AiDocumentCheckerEducationInsurance/>
                                                    : <></>
                                            }
                                        </div>
                                        : <></>
                                }
                            </div>
                        </div>
                    </div>
            }
        </>
    )
        ;
}